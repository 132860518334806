<template>
    <router-view v-slot="{ Component, route }">
      <!-- <transition name="fade-transform" mode="out-in"> -->
        <keep-alive>
          <component :is="Component" :key="route.path" v-if="!route.meta.noCache" />
        </keep-alive>
		<component :is="Component" :key="route.path" v-if="route.meta.noCache" />
      <!-- </transition> -->
    </router-view>
</template>
<script setup>
import { useRoute } from 'vue-router'
const route = useRoute()
</script>

<style lang="less" scoped>
// .app-main {
//   /* 50= navbar  50  */
//   // min-height: calc(100vh - 230px);
//   width: 100%;
//   position: relative;
//   overflow: hidden;
//   height:calc( 100% - 52px );
// }
.app-main {
  flex: auto;
  min-height: 0;
  height: 100%;
}
</style>
